//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  Parallax,
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Keyboard,
} from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import utils from '@/mixins/utils'
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-fade.min.css'
import 'swiper/modules/pagination.min.css'
import 'swiper/modules/autoplay.min.css'
import 'swiper/modules/keyboard.min.css'
import 'swiper/modules/parallax.min.css'
import 'swiper/modules/navigation.min.css'
Swiper.use([Pagination])
export default {
  mixins: [utils],
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  setup() {
    return {
      modules: [
        Parallax,
        Navigation,
        Pagination,
        EffectFade,
        Autoplay,
        Keyboard,
      ],
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        effect: this.data.effect === 'fade' ? 'fade' : null,
        loop: true,
        allowTouchMove: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        parallax: this.data.effect === 'parallax',
        modules: [
          Parallax,
          Navigation,
          EffectFade,
          Pagination,
          Autoplay,
          Keyboard,
        ],
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        autoplay: this.data.autoplay
          ? {
              delay: this.data.delay || 5000,
              disableOnInteraction: false,
            }
          : false,
        speed: this.data.speed || 300,
        breakpoints: {
          1024: {
            allowTouchMove: false,
          },
        },
        navigation: this.data.arrows
          ? {
              nextEl: '#swiper-button-next-' + this._uid,
              prevEl: '#swiper-button-prev-' + this._uid,
            }
          : false,
        on: {
          init: () => {
            const self = this
            const videos = this.$el.querySelectorAll('video')
            Array.prototype.forEach.call(videos, function (video) {
              self.$gsap.set(video, {
                volume: 0,
              })
            })
          },
          beforeTransitionStart: () => {
            const self = this
            const videos = document.querySelectorAll('video')
            Array.prototype.forEach.call(videos, function (video) {
              self.$gsap.to(video, {
                volume: 0,
                duration: self.data.speed / 1000 || 0.3,
              })
            })
          },
          transitionStart: (swiper) => {
            const activeIndex = swiper.activeIndex
            const activeSlide =
              document.getElementsByClassName('swiper-slide')[activeIndex]
            const activeSlideVideo =
              activeSlide.getElementsByTagName('video')[0]
            if (activeSlideVideo) {
              activeSlideVideo.play()
              this.$gsap.to(activeSlideVideo, {
                volume: 1,
                duration: this.data.speed / 1000 || 0.3,
              })
            }
          },

          transitionEnd: (swiper) => {
            const activeIndex = swiper.activeIndex
            const activeSlide =
              document.getElementsByClassName('swiper-slide')[activeIndex]
            const activeSlideVideo =
              activeSlide.getElementsByTagName('video')[0]
            const videos = this.$el.querySelectorAll('video')

            Array.prototype.forEach.call(videos, function (video) {
              if (activeSlideVideo !== video) {
                video.pause()
                video.currentTime = 0
              }
            })
          },
        },
      },
    }
  },
  methods: {
    getUrl(image, width) {
      if (image.attributes.mime === 'image/gif') {
        return image.attributes.url
      } else {
        return this.$cloudinary.image.url(image.attributes.hash, {
          gravity: 'auto:subject',
          width,
          crop: 'fill',
          dpr: 'auto',
          loading: 'lazy',
          quality: '70',
        })
      }
    },
  },
}
